<template>
    <Head :title="'User '+ getFullNameString + ' - VIP Fares'"></Head>
    <AppMainLayout
        :title="getFullNameString"
        :breadcrumbs="[{title:'Users', link: getRoute('users.index')}, getFullNameString]"
    >
        <template #content>
            <div class="row">
                <div class="col-3">
                    <acms-v-card
                        title="User"
                    >
                        <template #header-right>

                            <acms-v-switcher
                                v-model="form.active"
                                :title="form.active ? 'Active' : 'Not Active'"
                                class="ms-auto"

                                :disabled="getDisabled"
                            />
                        </template>
                        <template #default>
                            <div class="user-block">
                                <div class="user-block-info d-flex flex-column">
                                    <div class="position-relative">
                                        <img class="avatar" :src="getUserAvatar" style="object-fit: cover" alt="">
                                        <label v-if="!getDisabled">
                                            <input hidden type="file" @change="fileHandle" accept="image/*">
                                            <acms-v-icon name="pen"
                                                         class="avatar-replace bg-primary"
                                                         icon-color="text-white"/>
                                        </label>
                                    </div>
                                    <div class="user-block-name">{{ form.first_name }} {{ form.middle_name }}
                                        {{ form.last_name }}
                                    </div>
                                </div>
                            </div>
                            <div class="user-block-activity">
                                <div class="user-block-activity-row">
                                    <div>User created</div>
                                    <span>{{ props.resource.created_at }}</span>
                                </div>
                                <div class="user-block-activity-row">
                                    <div>Last time updated</div>
                                    <span>{{ props.resource.updated_at }}</span>
                                </div>
                                <div class="user-block-activity-row">
                                    <div>Last time logged in</div>
                                    <span>{{ last_time_login }}</span>
                                </div>

                            </div>
                            <div class="user-block-reset">
                                <acms-v-btn class="text-primary mt-4 mb-3 ps-0" @click="resetPasswordAlert = true"
                                            v-if="!getDisabled"
                                            title="Reset password"/>
                            </div>
                        </template>
                    </acms-v-card>
                </div>
                <div class="col-9">
                    <acms-v-tabs
                        :items="{
                        'details':'Details',
                        'permissions': 'Permissions',
                        'team': 'Team',
                    }"
                        :active="activeTab"
                        @afterChangeTab="activeTab = $event"
                    >
                        <template #tab-details>
                            <div class="row">
                                <acms-v-form-field
                                    label="User Type"
                                    name="user_type"
                                    placeholder="Real User"
                                    :modeSelect="{
                                        options:['Real', 'System'],
                                        }"
                                    hideHintInit
                                    v-model="form.user_type"
                                    class=" col-4"
                                    :errors="form.errors.user_type"
                                    required
                                />
                            </div>
                            <div>
                                <h3>Personal Data</h3>
                            </div>
                            <div class="row">
                                <acms-v-form-field
                                    label="First name"
                                    name="first_name"
                                    placeholder="ex. John"
                                    modeInput
                                    v-model="form.first_name"
                                    class=" col-4"
                                    :errors="form.errors"
                                    :readonly="getDisabled"
                                    required
                                />
                                <acms-v-form-field
                                    label="Last name"
                                    name="last_name"
                                    placeholder="ex. Doe"
                                    modeInput
                                    v-model="form.last_name"
                                    class=" col-4"
                                    :errors="form.errors"
                                    :readonly="getDisabled"
                                    required
                                />
                                <acms-v-form-field
                                    label="Middle name"
                                    name="middle_name"
                                    placeholder="ex. Doe"
                                    modeInput
                                    v-model="form.middle_name"
                                    class=" col-4"
                                    :errors="form.errors"
                                    :readonly="getDisabled"
                                />
                                <acms-v-form-field
                                    label="Personal email address"
                                    name="personal_email"
                                    modeInput
                                    v-model="form.personal_email"
                                    placeholder="ex. john_doe@gmail.com"
                                    class=" col-4"
                                    :errors="form.errors"
                                    :readonly="getDisabled"
                                />
                                <acms-v-form-field
                                    label="Personal phone number"
                                    name="personal_phone_number"
                                    placeholder="(983) XXX XX XX"
                                    class=" col-4"
                                    v-model="form.personal_phone"
                                    :errors="form.errors.personal_phone"
                                    :readonly="getDisabled"
                                    :modeInputWithDropdown="{
                                     selectOptions,
                                     phoneCode:true,
                                     keySelect:'phoneCode',
                                      search:{
                                         keys:['code', 'title'],
                                         excludeSymbols:['+']
                                     },
                                     selectedOptionOnInit:{key:'id', value: form.personal_phone.phoneCode.id },
                                }"/>
                                <acms-v-form-field
                                    label="Date range"
                                    placeholder="MM/DD/YYYY"
                                    modeDatepicker
                                    name="date_birth"
                                    class=" col-4"
                                    v-model="form.date_of_birth"
                                    :errors="form.errors.date_of_birth"
                                    :readonly="getDisabled"
                                />

                            </div>

                            <h3>Work Data</h3>
                            <div class="row">
                                <acms-v-form-field
                                    label="Display Name"
                                    name="display_name"
                                    placeholder="ex. John"
                                    modeInput
                                    v-model="form.display_name"
                                    class=" col-4"
                                    :errors="form.errors"
                                    :readonly="getDisabled"
                                    required
                                />
                                <acms-v-form-field
                                    label="Display Last Name"
                                    name="display_last_name"
                                    placeholder="ex. Doe"
                                    modeInput
                                    v-model="form.display_last_name"
                                    class=" col-4"
                                    :errors="form.errors"
                                    :readonly="getDisabled"
                                    required
                                />
                                <acms-v-form-field
                                    label="Current groups"
                                    placeholder="Select option"
                                    :modelValue="getCurrentGroup"
                                    :modeSelect="{
                                        options:roles,
                                        optionKeyTitle:'name',
                                        outValueKey: 'id',
                                        multi:true,
                                        toggleTagKeyTitle:'name'
                                        }"
                                    name="group_id"
                                    class="col-4"
                                    disabled
                                />
                                <acms-v-form-field
                                    label="Telegram ID"
                                    name="telegram_id"
                                    modeInput
                                    v-model="form.telegram_id"
                                    placeholder="1234566789"
                                    class=" col-4"
                                    :errors="form.errors"
                                    :readonly="getDisabled"
                                    required
                                />
                                <acms-v-form-field
                                    label="Work email"
                                    name="work_email"
                                    modeInput
                                    v-model="form.work_email"
                                    placeholder="ex. john_doe@gmail.com"
                                    class=" col-4"
                                    :errors="form.errors"
                                    :readonly="getDisabled"
                                    required
                                />

                                <acms-v-form-field
                                    label="Work phone number"
                                    name="work_phone"
                                    class=" col-4"
                                    placeholder="(983) XXX XX XX"
                                    v-model="form.work_phone"
                                    :errors="form.errors.work_phone"
                                    :readonly="getDisabled"
                                    required
                                    :modeInputWithDropdown="{
                                      search:{
                                         keys:['code', 'title'],
                                         excludeSymbols:['+']
                                     },
                                     selectOptions,
                                     phoneCode:true,
                                     keySelect:'phoneCode',
                                     selectedOptionOnInit:{key:'id', value: form.work_phone.phoneCode.id }
                                }"
                                />
                                <acms-v-form-field
                                    label="Work extension"
                                    name="work_extension"
                                    modeInput
                                    v-model="form.work_extension"
                                    class="col-4"
                                    placeholder="ex. 777"
                                    :errors="form.errors.work_extension"
                                    :readonly="getDisabled"
                                />
                            </div>


                            <h3>Remote Access</h3>
                            <div class="row">
                                <acms-v-form-field
                                    label="Access Type"
                                    placeholder="Select option"
                                    :model-value="form.access_type"
                                    :mode-select="{
                                        options: [{id: 1, name: 'Whitelist'}, {id: 2, name: 'Any Ips'}, {id: 3, name: 'Specific IPs'}],
                                        optionKeyTitle:'name',
                                        outValueKey: 'id',
                                        toggleTagKeyTitle:'name'
                                        }"
                                    @update:modelValue="updateAccessType"
                                    name="access_type"
                                    class="col-4"
                                />
                                <template v-if="form.access_type === 3">
                                    <div class="col-4 d-flex align-items-center">
                                        <acms-v-form-field
                                            label="Add Ips"
                                            placeholder="Current IPs"
                                            name="ips"
                                            v-model="newIp"
                                            class="flex-grow-1"
                                        />
                                        <button @click="addIp" style="margin-top: 8px; margin-left: 8px" class="btn btn-primary ml-2">Add</button>
                                    </div>
                                    <acms-v-form-field
                                        label="Current IPs"
                                        placeholder="Select option"
                                        :modelValue="form.ip_array"
                                        @update:modelValue="updateCurrentIps"
                                        :modeSelect="{
                                            options: [],
                                            showOptions:false,
                                            multi: true,
                                            toggleTagKeyTitle: 'value'
                                        }"
                                        name="group_id"
                                        class="col-4"
                                    />
                                </template>
                            </div>
                            <gds-credentials
                                :items="form.gds"
                                @update="form.gds = $event"
                                @updatedDeletedIds="gdsDeletedIds = $event"
                                :errors="form.errors"
                                :options="gds"
                                :readonly="getDisabled"
                            />
                        </template>
                        <template #tab-permissions>
                            <UserGroupPermissionsBox
                                show-title
                                label-group="Current group"
                                :group="form.roles"
                                @update:group="form.roles = $event"
                                v-model:permissions="form.permissions"

                                :groups-options="roles"
                                :permissions-groups="clients"
                                :selectedPermissions="resource.permissions"
                                :errors="form.errors"
                                :readonly="getDisabled"
                                @change-tab="locState.currentActivePermissionsTab = $event"
                            />
                        </template>
                      <template #tab-team>
                        <acms-v-form-field
                            label="Current team"
                            name="team_id"
                            :modeSelect="{
                                options: teams,
                                outValueKey:'id'
                            }"
                            v-model="form.team_id"
                            :errors="form.errors?.team_id"
                            class=" col-4"
                            required
                        />
                      </template>
                    </acms-v-tabs>


                    <acms-v-card title="Permissions Guide" v-if="activeTab == 'permissions'">
                        <permissions-guide
                            :collection="getPermissionsForGuideTable"
                            :readonly="getDisabled"

                        />
                    </acms-v-card>

                    <template v-if="logs">
                        <acms-v-card v-if="activeTab == 'details'"
                                     title="Recent activity"
                        >

                            <template #header-right>
                                <acms-v-btn icon="sync" icon-size="12"
                                            icon-color="text-primary"
                                            class="text-primary"
                                            title="Refresh"
                                            @click="refreshLogsHandle"
                                ></acms-v-btn>
                            </template>
                            <template #default>
                                <acms-v-table class=" "
                                              :data="logs"
                                              sort-keys="created-at,ip-address"
                                              @update:sort="recentActivityTableSortHandle"
                                              :columns="{
                                            'id' : '#',
                                            'created-at' : 'DATE & tIME',
                                            'module' : 'MODULE',
                                            'action' : 'ACTION',
                                            'ip-address' : 'IP',
                                            'location' : 'Location',
                                            'description' : 'View Log',
                                            'client_id' : 'Client ID'
                                   }"
                                              :columns-width="{
                                            'id': '52px',
                                            'created-at' : '170px',
                                            'module' : '110px',
                                            'action' : '160px',
                                            'ip-address' : '120px',
                                            'location' : '120px',
                                            'description' : '120px',
                                            'client_id': '120px'
                                  }"
                                >
                                    <template #header>
                                        <AcmsVTableHeader
                                            search-placeholder="Search"
                                        />
                                    </template>
                                    <template #bodyCell-id="{item}">
                                        <div class="text-end">{{ item.id }}</div>
                                    </template>

                                    <template #bodyCell-description="{item}">
                                        <div class="text-primary" v-tooltip="item.description" >log-{{ item.id }}</div>
                                    </template>
                                    <template #bodyCell-client_id="{item}">
                                     <a class="text-primary" :href="'https://crm.vipfares.com/clients/edit/'+item.client_id">{{item.client_id}}</a>
                                    </template>
                                </acms-v-table>
                            </template>
                        </acms-v-card>
                    </template>

                </div>
            </div>

            <acms-v-bottom-actions
                :hide-save-buttons="getDisabled"
                @save="saveHandle"
                @saveAndExit="saveAndExitHandle"
                @cancel="router.get(getRoute('users.index'))"
            />

            <acms-v-modal-alert
                v-model:show="resetPasswordAlert"
                title="Reset Password"
                text="Confirming will immediately reset the current password and send an email with new password setup instructions to the registered work email."
                leftButtonTitle="Cancel"
                rightButtonTitle="Yes, reset"
                @leftButtonClick="resetPasswordAlert = false"
                @rightButtonClick="resetPasswordHandle"
            />
        </template>
    </AppMainLayout>
</template>

<script>
export default {
    name: 'UserEditPage',
};
</script>

<script setup="">
import {computed, onMounted, reactive, ref} from 'vue';
import AcmsVTableHeader from '@components/ui/Table/partials/TableHeader.vue';

import {Head, router, useForm,} from '@inertiajs/vue3'
import AppMainLayout from "@layouts/AppMainLayout.vue";
import UserGroupPermissionsBox from "../../boxes/UserGroupPermissionsBox.vue";
import {getRoute} from "@plugins/useRoutes.js";
import GdsCredentials from "@pages/users/partials/gdsCredentials.vue";
import axios from "axios";
import {useAlertsManagerStore} from "@store/plugins/alerts-manager.js";
import AcmsVBtn from "@ui/Btn.vue";
import AcmsVModalAlert from "@ui/ModalAlert.vue";
import PermissionsGuide from "@pages/users/partials/permissionsGuide.vue";
import useTableSortable from "@ui/Table/useTableSortable.js";
import {toKebabCase} from "@libs/utils.js";
import AcmsVFormField from "@ui/FormField/FormField.vue";

const AlertsManager = useAlertsManagerStore()
const props = defineProps({
    resource: Object,
    message: String,
    status: String,
    roles: Array,
    dialCodes: Array,
    gds: Array,
    clients: Array,
    logs:Array,
    teams:Array,
    actions: {
        type: Array,
        default: () => []
    },
});
const getDisabled = computed(() => {
    return !props.actions.includes('Update')
})
const activeTab = ref('details');



const {
    personal_phone,
    personal_phone_prefix_id,
    work_phone_prefix_id,
    work_phone,
    last_time_login,
    team_id,
    userRoles,
    access_type,
    ips,
    user_type,
    ...resourceRest
} = props.resource

const locState = reactive({
    currentActivePermissionsTab: props.clients?.length > 0 ? toKebabCase(props.clients[0].name) : '',
    currentIps: ips ?? [],
})
const form = useForm({
    personal_phone: {phoneCode: {id: personal_phone_prefix_id}, text: personal_phone},
    work_phone: {phoneCode: {id: work_phone_prefix_id}, text: work_phone},
    ...resourceRest,
    access_type: props.resource.access_type,
    user_type: props.resource.user_type,
    ip_array : props.resource.ips ?? [],
    roles:  props.resource.userRoles  ?? null,
    team_id: props.resource.team_id ?? null,
})

const getCurrentGroup = computed(()=>{
    return props.resource.roles
})
const getPermissionsForGuideTable = computed(() => {
    const find = props.clients.find(item => toKebabCase(item.name) == locState.currentActivePermissionsTab)
    return find ? find.permissions : []
})

const getFullNameString = computed(() => {
    const {first_name, middle_name, last_name} = form
    return first_name + ' ' + last_name + ' ' + (middle_name ? middle_name + ' ' : '')
})
const selectOptions = props.dialCodes.map(item => {
    const {id, image, country, code} = item
    return {
        id, code,
        title: country,
        thumbnail: image,
    }
})

const {tableForm: recentActivityTableForm, tableSortHandle: recentActivityTableSortHandle} = useTableSortable(
    getRoute('users.edit', props.resource.id), {
        limit: 10,
        search: '',
        column: null,
        direction: null,

    })
const resetPasswordAlert = ref(false)
const newIp = ref('');
const addIp = () => {
    if (newIp.value.trim()) {
        locState.currentIps.push({ value: newIp.value.trim() });
        form.ip_array.push({ value: newIp.value.trim() });
        newIp.value = '';
    }
};
const gdsDeletedIds = ref([]);

const refreshLogsHandle = () => {
    router.get(getRoute('users.edit',  props.resource.id  ), {}, {preserveScroll:true})
}

const tempAvatar = ref(null)

const fileHandle = (event) => {
    const formData = new FormData();
    const file = event.target.files[0]

    const fileSizeMB = file.size / (1024 ** 2)

    if(fileSizeMB > 1){
        AlertsManager.add('Avatar size error, max 1 MB', 'error')
        return
    }

    formData.append('file', file)
    axios.post('/drop-zone/store-file', formData).then(({data}) => {
        form.image = [data.name]
    }, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    }).then(()=>{
        tempAvatar.value = URL.createObjectURL(event.target.files[0])
    }).catch(()=>{
        AlertsManager.add('Avatar upload error', 'error')
    })

}

const updateAccessType = (newValue) => {
    form.access_type = newValue;
};
const getUserAvatar = computed(() => {
    if (props.resource.image && !tempAvatar.value) {
        return props.resource.image.path
    }
    if (tempAvatar.value) {
        return tempAvatar.value
    }
})

const formTransform = (data) => {
    const {personal_phone, work_phone, gds, image, access_type, ...rest} = data

    const getPermissions = () => {
        const values = Object.values(data.permissions)
        return values.map((item) => {
            if (item.id) {
                return item.id
            } else {
                return item.map(item => item.id)
            }
        }).flat()
    }
    const object = {
        _method: 'put',
        ...rest,
        access_type: access_type ?? 1,
        personal_phone: personal_phone?.text ?? null,
        personal_phone_prefix_id: personal_phone?.phoneCode.id ?? null,
        work_phone: work_phone?.text ?? null,
        work_phone_prefix_id: work_phone?.phoneCode.id ?? null,
        gds_credentials: gds.filter(item => !item.id || item.__wasChanged).map(item => {
            const {id, user_id, gds_system_id, gds_id, pcc} = item
            if (id) {
                return {
                    id, user_id, gds_system_id, gds_id, pcc
                }
            }
            return {
                gds_system_id, gds_id, pcc
            }
        }),
        gds_for_delete: gdsDeletedIds.value ?? [],
        permissions: getPermissions(),
        resource_id: props.resource.id,
        roles: data.roles
    }
    if (tempAvatar.value) {
        object.image = image
    }
    return object
}


const updateCurrentIps = (newIps) => {
    locState.currentIps = newIps;
    form.ip_array = newIps;
};
const sendFormHandle = (callback = () => {
}) => {
    form.transform(formTransform)
        .post(getRoute('users.update', props.resource.id), {
            preserveScroll: true,
            onSuccess() {
                if (props.status == 'error') {
                    AlertsManager.add('Server Error', 'error')
                } else {
                    AlertsManager.add('Client successfully updated')
                }
                callback?.()
            },
            onError() {
                AlertsManager.add('Client has errors', 'error')
            }
        })
}

const resetPasswordHandle = () => {
    router.post(getRoute('password.reset.request'), {id: props.resource.id}, {
        onSuccess() {
            AlertsManager.add('New password setup instructions to the registered work email')
        },
        onFinish() {
            resetPasswordAlert.value = false
        }
    })
}

const saveHandle = () => sendFormHandle()

const saveAndExitHandle = () => {
    sendFormHandle(() => router.visit(getRoute('users.index')))
}
</script>

<style scoped lang="scss">
:deep([data-v-select-settings=withCode] .acms-v-select-dropdown) {
    width: 400px !important;
}

.user-block {
    &-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
            width: 136px;
            height: 136px;
            border-radius: 100%;
            margin-bottom: 16px;
        }

        .avatar-replace {
            border-radius: 50px;
            width: 24px;
            height: 24px;
            right: 8px;
            position: absolute;
            bottom: 24px;
            padding: 4px;
            color: white;
            cursor: pointer;
        }
    }

    &-name {
        color: #4B5563;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 140% */
    }

    &-activity {
        &-row {
            display: flex;
            justify-content: space-between;
            padding: 20px 0;
            padding-right: 16px;

            &:not(:first-child) {
                border-top: 1px solid #CED4DA;
            }

            div, span {
                font-size: 14px;
                font-style: normal;
                line-height: 20px; /* 142.857% */
                text-transform: uppercase;
            }

            div {
                font-weight: 600;
                color: #8093AD;
            }

            span {
                color: #343A40;
            }
        }
    }
}

</style>
